.checkboxLabel {
  min-height: initial;
}

.loader {
  position: absolute;
  padding-top: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  position: relative;
  
  &Loading {
    pointer-events: none;
    opacity: 0.5;
  }
}